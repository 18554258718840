
//    const env_name = "local"
const env_name = "demo"
var backurl = ""
if (env_name === "local") {
    backurl = "http://localhost:2053"
}
else if (env_name === "live") {
    backurl = "https://api.smart-gpt.org" // mainet

    // backurl ="http://43.204.99.101:7011" // testnet
}
else {
    //demo
    backurl="https://smartgptdemoapi.maticz.in" //demo
}

export default backurl;