import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateForm } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';


import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class FormUpdateModal extends React.Component {

    constructor(props) {
        console.log("propsprops", props);
        super(props);
        this.state = {
            id: this.props.record._id,
            identifier: this.props.identifier,
            Title: this.props.record.Title,
            Heading: this.props.record.Heading,
            Description: this.props.record.Description,
            content: this.props.record.content,
            file: {},
            errors: {},
            status: false
        };
    }
    componentDidMount(props) {
        console.log("data>>>>>>> :", this.props.status)

    }


    componentWillReceiveProps(nextProps) {
        //console.log("next Props>>>>",nextProps)
        if (nextProps.record) {
            console.log("nextProps.recordnextProps.", nextProps.record);
            this.setState({
                id: nextProps.record._id,
                identifier: nextProps.record.identifier,
                Title: nextProps.record.Title,
                Heading: nextProps.record.Heading,
                Description: nextProps.record.Description,
                content: nextProps.record.content,
                file: nextProps.record.file,
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success ) {
console.log("nextProps.auth.form.data.message",nextProps.auth.form.data.message);
                if(this.state.status == true){

                    console.log("asdfasdfasfasdfasdf", this.state.status)

            this.setState({ status: false })
            // console.log("nextProps.auth.form.data.message", nextProps.auth.form.data.message);
            // toast(nextProps.auth.form.data.message, {
            //     position: toast.POSITION.TOP_CENTER
            // });
            // this.props.fetchData()


            // $('#update-form-modal').modal('hide');


                }
            

        }
        $('#update-form-modal').modal('hide');
        $('#modal')
        // this.props.fetchData()
    }

    onChange = e => {

        console.log("e.target.value", e.target.value);
        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Heading') {
            this.setState({ Heading: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }
        if (e.target.id === 'content') {
            this.setState({ content: e.target.value });
        }



    };
    onFileChange = event => {
        var name = event.target.value;
        console.log("name-------------", event.target.files[0]);
        var file = event.target.files[0]

        let fileName = file.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile == "png" || extFile == "svg") {
            this.setState({ file: event.target.files[0] });
            this.setState({ imagepath: name });
        }

        else {
            toast("Allowed Only PNG File", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        // Update the state
        // this.setState({ file: event.target.files[0] });
        //    this.setState({ imagepath: name});

    };

    onFormUpdate = e => {
        e.preventDefault();
        console.log("this.state.id,", this.state.Title);
        const updateForm = {
            _id: this.state.id,
            identifier: "Landing_page1",
            Title: this.state.Title,
            Heading: this.state.Heading,
            Description: this.state.Description,
            content: this.state.content,
            file: this.state.file

        };
        this.setState({ status: true })
        console.log("updateForm___", updateForm);
        this.props.updateForm(updateForm)

        // window.location.reload();
    };

    Close = e => {
        // window.location.reload()
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-form-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Home</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.Close}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-form">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="form-update-id"
                                        type="text"
                                        required
                                        className="d-none" />
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">identifier</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                // onChange={this.onChange}
                                                value={"Landing_page1"}
                                                error={errors.identifier}
                                                id="identifier"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.identifier
                                                })}
                                            />
                                            <span className="text-danger">{errors.identifier}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                error={errors.Title}
                                                id="Title"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}
                                            />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="quoteTokenAdresses">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                error={errors.Heading}
                                                id="Heading"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}
                                            />
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"

                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">content</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                row="8"
                                                onChange={this.onChange}
                                                value={this.state.content}
                                                error={errors.content}
                                                id="content"

                                                className={classnames("form-control", {
                                                    invalid: errors.content
                                                })}
                                            ></textarea>
                                            <span className="text-danger">{errors.content}</span>
                                        </div>


                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                // value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div> */}

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.Close}>Close</button>
                                <a
                                    form="update-form"

                                    className="btn btn-primary" onClick={this.onFormUpdate}>
                                    Update
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FormUpdateModal.propTypes = {
    updateForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateForm }
)(withRouter(FormUpdateModal));
